import React from 'react'
import { graphql } from 'gatsby'
import PageTypes from '../../../../themes/gatsby-theme-kn/src/components/page-types/_PageTypes'
import Blocks from '../../../../themes/gatsby-theme-kn/src/components/blocks/_Blocks'

const ContentPageTemplate = ({ data }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const getLocale = (str) => {
    const arr = str.split('/')
    return arr[0]
  }

  const articles = data.articles.nodes
  const childs = articles
    .filter((child) => {
      return child.full_slug.split('/')[0] === getLocale(node.full_slug)
    })
    .sort((a, b) => {
      const contentA = JSON.parse(a.content)
      const contentB = JSON.parse(b.content)
      const aDate = contentA?.date
      const bDate = contentB?.date
      return new Date(bDate).getTime() - new Date(aDate).getTime()
    })

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  if (childs && childs.length > 0) {
    // die ContentPage erwartet es als 'collection'
    content.collection = childs
  }

  return React.createElement(
    PageTypes(content.component),
    {
      key: node.uid,
      content: content,
      name: node.name,
      type: content.component,
      locale: getLocale(node.full_slug),
      full_slug: node.full_slug,
      childs: childs,
      alternates: node.alternates,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query ContentPageTemplateQuery($id: String!, $category: String!) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      full_slug
      alternates {
        full_slug
      }
    }
    articles: allStoryblokEntry(
      filter: { field_category_string: { eq: $category } }
    ) {
      nodes {
        content
        full_slug
        name
        is_startpage
        field_component
      }
    }
  }
`

export default ContentPageTemplate
